<template>
  <!--begin::Mixed Widget 7-->
  <div :class="widgetClasses" class="card">
    <!--begin::Body-->
    <div class="card-body d-flex flex-column p-0">
      <!--begin::Stats-->
      <div class="flex-grow-1 card-p pb-0">
        <div class="d-flex flex-stack flex-wrap">
          <div class="d-flex align-items-center">
            <div class="me-5">
              <a href="#" class="text-dark text-hover-primary fw-bolder fs-3"
                >Oylik statistika</a
              >

              <div class="text-muted fs-7 fw-bold">
                Yangiliklarning oylik ko'rsatgichi
              </div>
            </div>
            
            <el-date-picker
              v-model="changedData"
              type="month"
              placeholder="Tanlash"
              @change="getStatisticByMonth()"
            />
          </div>

          <div :class="`text-${chartColor}`" class="fw-bolder fs-3">
            
          </div>
        </div>
      </div>
      <!--end::Stats-->

      <!--begin::Chart-->
      <apexchart
        class="mixed-widget-7-chart card-rounded-bottom"
        :options="chartOptions"
        :series="series"
        type="bar"
        :height="chartHeight"
      ></apexchart>
      <!-- <apexchart type="area" :height="chartHeight" :options="chartOptions" :series="series"></apexchart> -->
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Mixed Widget 7-->
</template>

<script>
import { defineComponent, ref, onMounted, reactive } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApiService from "@/core/services/ApiService";
import { createArray} from "@/core/helpers/create_array"


export default defineComponent({
  name: "widget-7",
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
  },
  setup(props) {
    const color = ref(props.chartColor);
    const labelColor = getCSSVariableValue("--bs-" + "gray-800");
    const strokeColor = getCSSVariableValue("--bs-" + "gray-300");
    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const lightColor = getCSSVariableValue("--bs-light-" + color.value);
    const dangerColor = getCSSVariableValue("--bs-" + 'danger');
    const successColor = getCSSVariableValue("--bs-" + 'success');
    const warningColor = getCSSVariableValue("--bs-" + 'warning');

  

    const chartOptions = reactive({
      chart: {
        fontFamily: "inherit",
        type: "bar",
        height: props.chartHeight,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        // bar: {
        //   horizontal: false,
        //   columnWidth: ["50%"],
        //   endingShape: "rounded",
        // },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories:[],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        y: 0,
        offsetX: 0,
        offsetY: 0,
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        type: "solid",
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return  val + " ta yangilik";
          },
        },
      },
      colors: [ successColor, dangerColor, warningColor, baseColor],
      grid: {
        padding: {
          top: 10,
        },
        borderColor: strokeColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    });

    // const series = [
    //   {
    //     name: "Net Profit",
    //     data: [15, 25, 15, 40, 20, 50],
    //   },
    //   {
    //     name: "Net Profit",
    //     data: [5, 15, 15, 46, 29, 45],
    //   },
    // ];


    const series = ref([
      {
        name: "Yangiliklar",
        data: [],
      }
    ]);

    const changedData = ref(new Date());

    const getStatisticByMonth = () =>{
      getMonthlyStatistic(changedData.value)
    }

    const getMonthlyStatistic = (value)=>{
      chartOptions.xaxis.categories = []
      let data;
      if(value){
        data = new Date(value)
      }else{
        data = new Date()
      }
      
      let year = data.getFullYear()
      let month = data.getMonth()
      let countMonth = new Date(year, month+1, 0).getDate();
      
      let arr = createArray(countMonth);
      
      chartOptions.xaxis.categories = arr
      
      ApiService.query('statistic/day', {params: {month: month+1}}).then(res=>{ 
        arr.forEach(item =>{
          if(res.data.length > 0){
            res.data.forEach(r =>{
              if(item-1 === r._id){
                series.value[0].data[item-1] = r.count;
              }else{
                series.value[0].data[item] = 0;
              }
            })
          }else{
            series.value[0].data[item] = 0;
          }  
        })
      })
    }


    

    onMounted(()=>{
      // getMonthlyStatistic()
      getStatisticByMonth()
    })

    return {
      chartOptions,
      series,
      changedData, getStatisticByMonth
    };
  },
});
</script>
