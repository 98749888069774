import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row gy-5 g-xl-8" }
const _hoisted_2 = { class: "col-xxl-4" }
const _hoisted_3 = { class: "col-xxl-8" }
const _hoisted_4 = { class: "row gy-5 g-xl-8" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "row gy-5 gx-xl-8" }
const _hoisted_7 = { class: "col-xxl-6" }
const _hoisted_8 = { class: "col-xxl-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MixedWidget2 = _resolveComponent("MixedWidget2")!
  const _component_MixedWidget10 = _resolveComponent("MixedWidget10")!
  const _component_MixedWidget7 = _resolveComponent("MixedWidget7")!
  const _component_ListWidget3 = _resolveComponent("ListWidget3")!
  const _component_TableWidget9 = _resolveComponent("TableWidget9")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_MixedWidget2, {
          "widget-classes": "card-xl-stretch mb-xl-8",
          "widget-color": "danger",
          "chart-height": "200",
          "stroke-color": "#cb1e46"
        })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_MixedWidget10, {
          "widget-classes": "card-xxl-stretch-100 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "435"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_MixedWidget7, {
          "widget-classes": "card-xxl-stretch-100 mb-5 mb-xl-8",
          "chart-color": "primary",
          "chart-height": "350"
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_ListWidget3, { "widget-classes": "card-xxl-stretch mb-xl-3" })
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_TableWidget9, { "widget-classes": "card-xxl-stretch mb-5 mb-xl-8" })
      ])
    ])
  ], 64))
}