

export const createArray = (leng: number): number[] => {
    const arr:number[] = [];
    let i = 0;
    while(i <= leng){
        arr.push(i)
        i++
    }
    return arr
  };