
import { defineComponent, onMounted, ref } from "vue";
import Dropdown2 from "@/components/dropdown/Dropdown2.vue";
import ApiService from "@/core/services/ApiService";
import { initImgPath } from "@/core/helpers/imgpath";

export default defineComponent({
  name: "kt-widget-3",
  components: {
    Dropdown2,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const list = ref([
      
    ])

    onMounted(()=>{
      ApiService.get('statistic/most').then(res=>{
        list.value = res.data.map(item=>{
          item.color = 'success'
          return item
        })
      })
    })
    

    return {
      list,
      initImgPath
    };
  },
});
