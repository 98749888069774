
import { defineComponent, onMounted, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "widget-12",
  props: {
    widgetClasses: String,
    chartColor: String,
    chartHeight: String,
  },
  setup(props) {
    const color = ref(props.chartColor);
    const labelColor = getCSSVariableValue("--bs-gray-500");
    const borderColor = getCSSVariableValue("--bs-gray-200");
    const secondaryColor = getCSSVariableValue("--bs-" + 'success');
    const baseColor = getCSSVariableValue("--bs-" + color.value);
    const dangerColor = getCSSVariableValue("--bs-" + 'danger');
    const successColor = getCSSVariableValue("--bs-" + 'success');
    const warningColor = getCSSVariableValue("--bs-" + 'warning');
    

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "bar",
        height: props.chartHeight,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: ["50%"],
          endingShape: "rounded",
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: ["Yan","Fev", "Mar", "Apr", "May", "Iyun", "Iyul", "Avg", "Sen", "Okt", "Noy", "Dek"],
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        y: 0,
        offsetX: 0,
        offsetY: 0,
        labels: {
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
      },
      fill: {
        type: "solid",
      },
      states: {
        normal: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        hover: {
          filter: {
            type: "none",
            value: 0,
          },
        },
        active: {
          allowMultipleDataPointsSelection: false,
          filter: {
            type: "none",
            value: 0,
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return  val + " ta yangilik";
          },
        },
      },
      colors: [ successColor, dangerColor, warningColor, baseColor],
      grid: {
        padding: {
          top: 10,
        },
        borderColor: borderColor,
        strokeDashArray: 4,
        yaxis: {
          lines: {
            show: true,
          },
        },
      },
    };

    const series = ref([
      {
        name: "O'yinlar",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Taomlar",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Sport",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      {
        name: "Technologiyalar",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
      
    ]);
    const counts = ref([])
    onMounted(()=>{
      let data = new Date();
      let year = data.getFullYear()
      ApiService.get('statistic/category').then(res=>{
        counts.value = res.data
      })

      ApiService.query('statistic/bycategory', {params: {year: year}}).then(res=>{
        res.data.forEach(item =>{
          if(item.slug == "game"){
            item.monthlyStatistics.forEach(i =>{
              series.value[0].data[i.month - 1] = i.count
            })
          }
          if(item.slug == "food") {
            item.monthlyStatistics.forEach(i =>{
              series.value[1].data[i.month - 1] = i.count
            })
          } 
          if(item.slug == "sport") {
            item.monthlyStatistics.forEach(i =>{
              series.value[2].data[i.month - 1] = i.count
            })
          } 
          else{
            item.monthlyStatistics.forEach(i =>{
              series.value[3].data[i.month - 1] = i.count
            })
          }
        })
        
      })
    })

    return {
      chartOptions,
      series,
    };
  },
});
